
// components

// Import styles
import '../scss/styles.scss';

// is in viewport helper
window.isInViewport = function(element) {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= ((window.innerHeight || html.clientHeight)) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}


const toAnimate = document.querySelectorAll('.animate');

toAnimate.forEach(el => {
	if(isInViewport(el)) {
		el.classList.add('show');
	}
});

document.addEventListener('scroll', function() {
	toAnimate.forEach(el => {
		if(isInViewport(el) && !el.classList.contains('show')) {
			el.classList.add('show');
		}
	});
});


setTimeout(function() {
	document.querySelector('.home__hero h1').classList.add('show');
}, 100);